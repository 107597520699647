
import { defineComponent, computed } from "vue";
import { toolbarWidthFluid } from "@/core/helpers/config";
import { useStore } from "vuex";

export default defineComponent({
  name: "KToolbar",
  props: {
    breadcrumbs: Array,
    title: String,
  },
  setup() {

		const store = useStore();
    const isAdmin = computed(() => store.getters["userRole"] === 1);

    return {
      toolbarWidthFluid,
      isAdmin
    };
  },
});
