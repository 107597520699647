
import {
  defineComponent,
  computed,
  onMounted,
  onBeforeMount,
  watch,
  reactive,
  onActivated,
} from "vue";
import { useStore } from "vuex";
import { useRoute, useRouter } from "vue-router";
import KTAside from "@/layout/aside/Aside.vue";
import KTHeader from "@/layout/header/Header.vue";
import KTFooter from "@/layout/footer/Footer.vue";
import HtmlClass from "@/core/services/LayoutService";
import KTToolbar from "@/layout/toolbar/Toolbar.vue";
import KTScrollTop from "@/layout/extras/ScrollTop.vue";
import KTUserMenu from "@/layout/header/partials/ActivityDrawer.vue";
import KTLoader from "@/components/Loader.vue";
import KTCreateApp from "@/components/modals/wizards/CreateAppModal.vue";
//import KTExplore from "@/layout/extras/Explore.vue";
import KTDrawerMessenger from "@/layout/extras/DrawerMessenger.vue";
import { Actions } from "@/store/enums/StoreEnums";
import {
  MenuComponent,
  DrawerComponent,
  ScrollComponent,
} from "@/assets/ts/components/index";
import { removeModalBackdrop } from "@/core/helpers/dom";
import {
  toolbarDisplay,
  loaderEnabled,
  contentWidthFluid,
  loaderLogo,
  asideEnabled,
  subheaderDisplay,
  themeLightLogo,
  themeDarkLogo,
  themeLightLogoFTVA,
  themeDarkLogoFTVA,
  themeLightLogoTNSW,
  themeDarkLogoTNSW,
} from "@/core/helpers/config";

import JwtService from "@/core/services/JwtService";
import store from "@/store";

export default defineComponent({
  name: "Layout",
  components: {
    KTAside,
    KTHeader,
    KTFooter,
    KTToolbar,
    KTScrollTop,
    KTCreateApp,
    KTUserMenu,
    //KTExplore,
    KTDrawerMessenger,
    KTLoader,
  },
  data() {
    return {
      currentUser: {
        id: 0,
        name: "",
        role: 0,
      },
    };
  },
  setup() {
    const store = useStore();
    const route = useRoute();
    const router = useRouter();
    //let currentUser = reactive({});

    // show page loading
    store.dispatch(Actions.ADD_BODY_CLASSNAME, "page-loading");

    // initialize html element classes
    HtmlClass.init();

    const pageTitle = computed(() => {
      return store.getters.pageTitle;
    });

    const breadcrumbs = computed(() => {
      return store.getters.pageBreadcrumbPath;
    });

    const verifyAuth = () => {
      const token = JwtService.getToken();
      if (token == "") {
        router.push({ name: "sign-in" });
      }
      store
        .dispatch(Actions.VERIFY_AUTH)
        .then(() => {
          //currentUser = store.getters.currentUser;
          console.log('verifyAuth');
        })
        .catch(() => {
          router.push({ name: "sign-in" });
        });
    };

    //onActivated(() => {
    onBeforeMount(() => {
      //verifyAuth();
    });

    onMounted(() => {
      //check if current user is authenticated
      if (!store.getters.isUserAuthenticated) {
        router.push({ name: "sign-in" });
      }

      DrawerComponent.bootstrap();
      ScrollComponent.bootstrap();
      DrawerComponent.updateAll();
      ScrollComponent.updateAll();

      // Simulate the delay page loading
      setTimeout(() => {
        // Remove page loader after some time
        store.dispatch(Actions.REMOVE_BODY_CLASSNAME, "page-loading");
      }, 500);
    });

    watch(
      () => route.path,
      () => {
        MenuComponent.hideDropdowns(undefined);

        DrawerComponent.hideAll();

        // check if current user is authenticated
        if (!store.getters.isUserAuthenticated) {
          router.push({ name: "sign-in" });
        }

        removeModalBackdrop();
      }
    );

    return {
      toolbarDisplay,
      loaderEnabled,
      contentWidthFluid,
      loaderLogo,
      asideEnabled,
      subheaderDisplay,
      pageTitle,
      breadcrumbs,
      themeLightLogo,
      themeDarkLogo,
      themeLightLogoFTVA,
      themeDarkLogoFTVA,
      themeLightLogoTNSW,
      themeDarkLogoTNSW,
      //currentUser,
    };
  },

  methods: {

    async verifyAuth() {
      
      const router = useRouter();
      const token = JwtService.getToken();
      if (token == "" || token === null) {
        router.push({ name: "sign-in" });
      }
      store
        .dispatch(Actions.VERIFY_AUTH)
        .then(() => {
          this.currentUser = store.getters.currentUser.user;
          //console.log(this.currentUser);
        })
        .catch(() => {
          router.push({ name: "sign-in" });
        });
    },

    async tryVerify() {
      const resp = await this.verifyAuth();
    }
  },

  beforeMount() {
    this.tryVerify();
  },
});
