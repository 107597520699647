
import { defineComponent, onMounted, onUpdated } from "vue";
import { useI18n } from "vue-i18n";
import { ToggleComponent } from "@/assets/ts/components/_ToggleComponent";
import KTMenu from "@/layout/aside/Menu.vue";
import { asideTheme } from "@/core/helpers/config";

export default defineComponent({
  name: "KTAside",
  components: {
    KTMenu,
  },
  props: {
    lightLogo: String,
    darkLogo: String,
    lightLogoFTVA: String,
    darkLogoFTVA: String,
    lightLogoTNSW: String,
    darkLogoTNSW: String,
    currentUser: Object,
  },
  watch: {
    currentUser: function (val) {
      //console.log(val);
    },
  },
  setup(props) {
    const { t } = useI18n();

    onMounted(() => {
      ToggleComponent.reinitialization();
    });

    onUpdated(() => {
      ToggleComponent.bootstrap();
    });

    return {
      asideTheme,
      t,
    };
  },
});
