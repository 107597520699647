const DocMenuConfig = [
   {
      heading: "Production Dashboard",
      route: "/apps",
      role: -1,
      pages: [
         {
            heading: "Dashboard",
            route: "/dashboard",
            svgIcon: "/media/icons/duotune/general/gen014.svg",
            fontIcon: "bi-menu-button-wide",
            role: 1,
            limitAccess: false,
         },
         {
            heading: "My Projects",
            route: "/my-projects",
            svgIcon: "/media/icons/duotune/general/gen014.svg",
            fontIcon: "bi-menu-button-wide",
            role: -1,
            limitAccess: false,
         },
         {
            heading: "New Project Request",
            route: "/new-project-request",
            svgIcon: "/media/icons/duotune/general/gen014.svg",
            fontIcon: "bi-journal-plus",
            role: -1,
            limitAccess: false
         },
         {
            heading: "Upload Files",
            route: "/upload-files",
            svgIcon: "/media/icons/duotune/general/gen014.svg",
            fontIcon: "bi-cloud-upload",
            role: -1,
            limitAccess: false,
         },
         {
            heading: "Track Open Projects",
            route: "/projects",
            svgIcon: "/media/icons/duotune/general/gen014.svg",
            fontIcon: "bi-view-list",
            role: -1,
            limitAccess: false,
         },
         {
            heading: "Review Projects",
            route: "/review-projects",
            svgIcon: "/media/icons/duotune/general/gen014.svg",
            fontIcon: "bi-journal-check",
            role: -1,
            limitAccess: false,
         },
         {
            heading: "Project Communication",
            route: "/project-communication",
            svgIcon: "/media/icons/duotune/communication/com007.svg",
            fontIcon: "bi-chat-square-text",
            role: 1,
            limitAccess: false,
         },
         {
            heading: "Browse Completed Projects",
            route: "/completed-projects",
            svgIcon: "/media/icons/duotune/general/gen014.svg",
            fontIcon: "bi-images",
            role: -1,
            limitAccess: false,
         },
         {
            heading: "Credit Points Options",
            route: "/account-usage",
            svgIcon: "/media/icons/duotune/general/gen014.svg",
            fontIcon: "bi-bar-chart-line",
            role: -1,
            limitAccess: true,
            access: {
               creditPointsOptions: 1
            }
         },
         {
            heading: "Video Training",
            route: "/video-training",
            svgIcon: "/media/icons/duotune/general/gen014.svg",
            fontIcon: "bi-camera-video",
            role: -1,
            limitAccess: false,
         },
         /*      {
                 heading: "Booking Calendar",
                 route: "/booking-calendar",
                 svgIcon: "/media/icons/duotune/general/gen014.svg",
                 fontIcon: "bi-calendar3",
                 role: -1,
               },
               {
                 heading: "Video Equipment Request",
                 route: "/video-equipment-request",
                 svgIcon: "/media/icons/duotune/general/gen014.svg",
                 fontIcon: "bi-briefcase-fill",
                 role: -1,
               },
         */
      ],
   },
   {
      heading: "Admin",
      route: "/admin",
      role: 1,
      pages: [
         {
            heading: "Users",
            route: "/admin/users",
            svgIcon: "/media/icons/duotune/general/gen014.svg",
            fontIcon: "bi-people-fill",
            role: 1,
            limitAccess: false,
         },
      ],
   },
];

export default DocMenuConfig;
