
import { defineComponent, onMounted, onActivated, ref } from "vue";
import { useI18n } from "vue-i18n";
import { useRoute } from "vue-router";
import { ScrollComponent } from "@/assets/ts/components/_ScrollComponent";
import { MenuComponent } from "@/assets/ts/components/MenuComponent";
import { version } from "@/core/helpers/documentation";
import { asideMenuIcons } from "@/core/helpers/config";
import MainMenuConfig from "@/core/config/MainMenuConfig";
import store from "@/store";

import { Actions } from "@/store/enums/StoreEnums";

export default defineComponent({
  name: "kt-menu",
  components: {},
  props: {
    currentUser: Object,
  },
  data() {
    return {
      //currentUser: {},
    };
  },
  computed: {
    userRole() {
      return store.getters.userRole;
    },
  },
  watch: {
    currentUser: function (val) {
      //console.log(val);
    },
  },
  setup() {
    const { t, te } = useI18n();
    const route = useRoute();
    const scrollElRef = ref<null | HTMLElement>(null);

    onMounted(() => {
      ScrollComponent.reinitialization();
      MenuComponent.reinitialization();
      if (scrollElRef.value) {
        scrollElRef.value.scrollTop = 0;
      }
    });

    const translate = (text) => {
      if (te(text)) {
        return t(text);
      } else {
        return text;
      }
    };

    const hasActiveChildren = (match) => {
      return route.path.indexOf(match) !== -1;
    };

    return {
      hasActiveChildren,
      MainMenuConfig,
      asideMenuIcons,
      version,
      translate,
    };
  },

  beforeMount() {
/*    this.currentUser = store.getters.currentUser.user;
    if( typeof(this. ) !== 'undefined') {
      // location.reload();
    }
*/
  },

});
