import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, normalizeClass as _normalizeClass, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-fef302c0"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  class: "aside-logo flex-column-auto",
  id: "kt_aside_logo"
}
const _hoisted_2 = {
  key: 0,
  href: "#"
}
const _hoisted_3 = ["src"]
const _hoisted_4 = {
  key: 1,
  href: "#"
}
const _hoisted_5 = ["src"]
const _hoisted_6 = {
  key: 2,
  href: "#"
}
const _hoisted_7 = ["src"]
const _hoisted_8 = {
  key: 3,
  href: "#"
}
const _hoisted_9 = ["src"]
const _hoisted_10 = { class: "aside-menu flex-column-fluid" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_KTMenu = _resolveComponent("KTMenu")!

  return (_openBlock(), _createElementBlock("div", {
    id: "kt_aside",
    class: _normalizeClass(["aside aside-hoverable", [
      _ctx.asideTheme === 'light' && 'aside-dark',
      _ctx.asideTheme === 'dark' && 'aside-dark',
    ]]),
    "data-kt-drawer": "true",
    "data-kt-drawer-name": "aside",
    "data-kt-drawer-activate": "{default: true, lg: false}",
    "data-kt-drawer-overlay": "true",
    "data-kt-drawer-width": "{default:'200px', '300px': '250px'}",
    "data-kt-drawer-direction": "start",
    "data-kt-drawer-toggle": "#kt_aside_mobile_toggle"
  }, [
    _createElementVNode("div", _hoisted_1, [
      (_ctx.asideTheme === 'dark')
        ? (_openBlock(), _createElementBlock("a", _hoisted_2, [
            _createElementVNode("img", {
              alt: "Logo",
              src: _ctx.darkLogoFTVA,
              class: "h-40px logo"
            }, null, 8, _hoisted_3)
          ]))
        : _createCommentVNode("", true),
      (_ctx.asideTheme === 'light')
        ? (_openBlock(), _createElementBlock("a", _hoisted_4, [
            _createElementVNode("img", {
              alt: "Logo",
              src: _ctx.lightLogoFTVA,
              class: "h-40px logo"
            }, null, 8, _hoisted_5)
          ]))
        : _createCommentVNode("", true),
      (_ctx.asideTheme === 'dark')
        ? (_openBlock(), _createElementBlock("a", _hoisted_6, [
            _createElementVNode("img", {
              alt: "Logo",
              src: _ctx.darkLogoTNSW,
              class: "h-40px logo"
            }, null, 8, _hoisted_7)
          ]))
        : _createCommentVNode("", true),
      (_ctx.asideTheme === 'light')
        ? (_openBlock(), _createElementBlock("a", _hoisted_8, [
            _createElementVNode("img", {
              alt: "Logo",
              src: _ctx.lightLogoTNSW,
              class: "h-40px logo"
            }, null, 8, _hoisted_9)
          ]))
        : _createCommentVNode("", true)
    ]),
    _createElementVNode("div", _hoisted_10, [
      _createVNode(_component_KTMenu, { currentUser: _ctx.currentUser }, null, 8, ["currentUser"])
    ])
  ], 2))
}